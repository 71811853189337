import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  getCountries,
  orgCurrencyListSelector,
  paymentTermsList,
  setLoaderState,
} from "../../../../common/commonSlice";
import { getItemDetail } from "../../itemSlice";
import ConsumerStatement from "../../../../common/components/detailsPage/ConsumerStatement";
import "./ItemDetail.css";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import {
  InventoryItemValues,
  ObjectType,
  customerDetailsValues,
} from "../../../../../types";
import ConsumerOverView from "../../../../common/components/detailsPage/overView/ConsumerOverView";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import OutstandingAmount from "../../../../common/components/detailsPage/overView/OutstandingAmount";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { AccountsImg, SalesDefault } from "../../../../../assets/images";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";
import ItemDetailHeader from "./ItemDetailHeader";
import { useGstSelect } from "../../../../common/components/transactions";
import { getGstTaxList } from "../../../gst/gstSlice";
import { EXEMPTION_TYPES } from "../../../../common/components/transactions/gstSelect/constants";
import { ITEM_TYPE } from "../constants";

function ItemDetail() {
  type LocationProps = {
    haveTransaction: boolean;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const locationState = location?.state as LocationProps;
  const existTransaction = locationState?.haveTransaction
    ? locationState.haveTransaction
    : false;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const detailsInitialValues = {
    id: null,
    name: "",
    hsn_or_sac_code: "",
    account_id: "",
    isGstOrg: false,
    account_name: "",
    rate: 0,
    currency_id: null,
    currency_code: "",
    tax_list: [],
    taxes: [],
    tax_preference: "",
    exemption_reason_id: null,
    type: "",
    item_information: [],
    sales_description: "",
    purchase_rate: 0,
    purchase_account_id: null,
    purchase_account_name: "",
    purchase_description: "",
    is_associated: false,
    intra_state_id: null,
    inter_state_id: null,
    is_inter_state_group: false,
    is_intra_state_group: false,
  };
  const [itemData, setItemData] =
    useState<InventoryItemValues>(detailsInitialValues);
  const [purchaseInfo, setPurchaseInfo] = useState<ObjectType>({});
  const [salesInfo, setSalesInfo] = useState<ObjectType>({});
  const [defaultItemGst, setDefaultItemGst] = useState<ObjectType>();
  const [subModulePermission, setSubModulePermission] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("overview");
  const { fetchGstTaxList } = useGstSelect();

  let subModules: any[] = [];
  useEffect(() => {
    const organizationRole = JSON.parse(
      sessionStorage.getItem("organizationRole") || "{}"
    );
    subModules = organizationRole.length
      ? organizationRole.map((permission: any) => {
          return permission.subModule;
        })
      : [];
    let unique = Array.from(new Set(subModules));
    setSubModulePermission(unique);
  }, [
    currentUserInfo.organization_id,
    sessionStorage.getItem("organizationRole"),
  ]);

  useEffect(() => {
    if (editId) {
      getCustomerDetails(Number(editId));
    }
  }, [editId, currentUserInfo.organization_id, existTransaction]);
  useEffect(() => {
    const storedTab = sessionStorage.getItem("activeCustomerTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
    // dispatch(addressReset());
  }, []);

  useEffect(() => {
    getAllCountries();
  }, [currentUserInfo.organization_id]);

  /**
   * Fetch all country list
   */
  const getAllCountries = async () => {
    await dispatch(getCountries());
  };
  /**
   * Setting active tab in session storage
   */
  function handleTabClick(tab: string) {
    setActiveTab(tab);
    sessionStorage.setItem("activeCustomerTab", tab);
  }
  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (editId: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getItemDetail({
        item_id: editId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let itemInfo = response;
        itemInfo.item_information?.map((item: ObjectType) => {
          if (item.is_sales) {
            setSalesInfo(item);
          } else if (item.is_purchase) {
            setPurchaseInfo(item);
          }
        });
        const interStateGstList: any = await dispatch(
          getGstTaxList({
            stateType: "Inter State",
            orgId: currentUserInfo.organization_id,
            transactionSource: "Invoices",
          })
        );
        const intraStateGstList: any = await dispatch(
          getGstTaxList({
            stateType: "Intra State",
            orgId: currentUserInfo.organization_id,
            transactionSource: "Invoices",
          })
        );
        const interStateTaxObj = interStateGstList.payload?.find(
          (item: ObjectType) => item?.id === itemInfo.inter_state_id
        );
        const intraStateTaxObj = intraStateGstList.payload?.find(
          (item: ObjectType) => item?.id === itemInfo.intra_state_id
        );
        setDefaultItemGst({
          interStateGst: `${interStateTaxObj?.name} (${interStateTaxObj?.rate}%)`,
          intraStateGst: `${intraStateTaxObj?.name} (${intraStateTaxObj?.rate}%)`,
        });
        setItemData(itemInfo);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.customerDetail));
      }
    }
  };

  const getPaymentsTerms = async () => {
    dispatch(paymentTermsList(currentUserInfo.organization_id));
  };

  return (
    <div className="">
      <div
        className="card card-dashboard customer-detail-card w-100"
        id="customer-detail-card"
      >
        <ItemDetailHeader itemData={itemData} />
        <div className="tab-container w-100 px-0">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overview" ? "nav-link active" : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overview")}
              >
                Overview
              </button>
            </li>
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overview"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="tab-content-overview-without-border">
                <div className="overview-wrapper tab-content-wrapper">
                  <section className="section-blocks other-dets-section">
                    <div className="other-wrapper section-content-wrapper">
                      <div className="row g-0">
                        <div className="col">
                          <div className="field-row">
                            <div className="field-title">
                              <span>Item Type</span>
                            </div>
                            <div className={`field-value`}>
                              <span>{ITEM_TYPE.find(item => item.key === itemData.type)?.value}</span>
                            </div>
                          </div>
                          {itemData.tax_preference && (
                            <div className="field-row">
                              <div className="field-title">
                                <span>Tax Preference</span>
                              </div>
                              <div className={`field-value`}>
                                <span>{EXEMPTION_TYPES.find(item => item.value === itemData.tax_preference)?.key}</span>
                              </div>
                            </div>
                          )}
                          {itemData.hsn_or_sac_code && (
                            <div className="field-row">
                              <div className="field-title">
                                <span>
                                  {itemData.type === "GOODS"
                                    ? "HSN Code"
                                    : "SAC Code"}
                                </span>
                              </div>
                              <div className={`field-value`}>
                                <span>{itemData.hsn_or_sac_code}</span>
                              </div>
                            </div>
                          )}
                          {itemData.inter_state_id &&
                            defaultItemGst?.interStateGst && (
                              <div className="field-row">
                                <div className="field-title">
                                  <span>Inter State Tax Rate</span>
                                </div>
                                <div className={`field-value`}>
                                  <span>{defaultItemGst?.interStateGst}</span>
                                </div>
                              </div>
                            )}
                          {itemData.intra_state_id &&
                            defaultItemGst?.intraStateGst && (
                              <div className="field-row">
                                <div className="field-title">
                                  <span>Intra State Tax Rate</span>
                                </div>
                                <div className={`field-value`}>
                                  <span>{defaultItemGst?.intraStateGst}</span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {purchaseInfo?.rate && (
                <div className="tab-content-overview purchase-sales-info">
                  <div className="overview-wrapper tab-content-wrapper">
                    <section className="section-blocks other-dets-section">
                      <h2>
                        {/* <img
                      src={DocIconSquare}
                      width="24"
                      height="24"
                      alt="Other details"
                    /> */}
                        Purchase Information
                      </h2>
                      <div className="other-wrapper section-content-wrapper">
                        <div className="row g-0">
                          <div className="col">
                            <div className="field-row">
                              <div className="field-title">
                                <span>Cost price</span>
                              </div>
                              <div className="field-value">
                                <span>{purchaseInfo?.rate}</span>
                              </div>
                            </div>
                            <div className="field-row">
                              <div className="field-title">
                                <span>Purchase Account</span>
                              </div>
                              <div className="field-value">
                                <span>{purchaseInfo?.account_name}</span>
                              </div>
                            </div>
                            {purchaseInfo.description && (
                              <div className="field-row">
                                <div className="field-title">
                                  <span>Description</span>
                                </div>
                                <div className="field-value">
                                  <span>{purchaseInfo?.description}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              )}
              {salesInfo?.rate && (
                <div className="tab-content-overview">
                  <div className="overview-wrapper tab-content-wrapper">
                    <section className="section-blocks other-dets-section">
                      <h2>
                        {/* <img
                      src={DocIconSquare}
                      width="24"
                      height="24"
                      alt="Other details"
                    /> */}
                        Sales Information
                      </h2>
                      <div className="other-wrapper section-content-wrapper">
                        <div className="row g-0">
                          <div className="col">
                            <div className="field-row">
                              <div className="field-title">
                                <span>Selling price</span>
                              </div>
                              <div className="field-value">
                                <span>{salesInfo?.rate}</span>
                              </div>
                            </div>
                            <div className="field-row">
                              <div className="field-title">
                                <span>Sales Account</span>
                              </div>
                              <div className="field-value">
                                <span>{salesInfo?.account_name}</span>
                              </div>
                            </div>
                            {salesInfo.description && (
                              <div className="field-row">
                                <div className="field-title">
                                  <span>Description</span>
                                </div>
                                <div className="field-value">
                                  <span>{salesInfo?.description}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              )}
              {/* <AuditTrail
                title="Audit Trail"
                transactionType="items"
                transactionId={Number(itemData.id)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetail;
