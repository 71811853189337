import { useEffect, useMemo, useState } from "react";
import { Row } from "react-table";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import { DateFormatHandler, handleNegativeValueWithTransactionType } from "../../../../../helpers/helper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { ObjectType, TableData } from "../../../../../types";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import EmptyReport from "../../../../common/components/EmptyReport";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import AccountNameDisplay from "../../../../common/components/accountNameDispaly/AccountNameDisplay";

type Props = {
  accountTransactionData: any;
  filterString?: string;
};
const DayBookTable = (props: Props) => {
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [orgCurrencyCode, setOrgCurrencyCode] = useState("");
  const subModulePermission = useSubModulePermission();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["action"]);

  useEffect(() => {
    setOrgCurrencyCode(
      orgCurrencyList.filter((item: ObjectType) => {
        return item?.id === props.accountTransactionData.currency_id;
      })[0]?.code
    );
  }, [props.accountTransactionData.currency_id]);

  useEffect(() => {
    if (
      Object.values(currentUserInfo.user_access_mapping).toString().split(",")
        .length <= 1
    )
      setHiddenColumns(["action", "Organization"]);
    else setHiddenColumns(["action"]);
  }, [currentUserInfo.user_access_mapping]);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        className: "",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "table_account_name",
      },
      {
        Header: "Organization",
        accessor:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organizationName"
            : "Organization",
        className: "",
      },
      {
        Header: "Transaction Details",
        accessor: "show.transactionDetails",
        className: "",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "",
      },
      {
        Header: "Reference #",
        accessor: "show.reference",
        className: "",
      },
      {
        Header: "Debit",
        accessor: "show.debit",
        className: "text-align-right",
      },
      {
        Header: "Credit",
        accessor: "show.credit",
        className: "text-align-right",
      },
      {
        Header: "Balance",
        accessor: "show.balance",
        className: "text-align-right",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    [props.accountTransactionData, hiddenColumns]
  );
  const showGroup = (item: any) => {
    return {
      show: {
        date: joinTransactionNumber(item?.group_name),
        action: "",
      },
      colSpan: {
        date:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? 9
            : 8,
      },
      className: "customRowTitle",
    };
  };
  const showTransactions = (item: any, navigation: boolean, type: string) => {
    if (type === "transaction") {
      return {
        show: {
          date:
            type === "transaction"
              ? DateFormatHandler(item?.transaction_date)
              : item?.transaction_date,
          account: (
            <AccountNameDisplay
              accountName={item?.account}
              ledgerRoot={item?.account_path}
            />
          ),
          organizationName: item?.organization_name,
          transactionDetails: item?.transaction_detail,
          transactionType: item?.transaction_type,
          reference: item?.reference_number,
          debit:
            item?.transaction === "Dr"
              ? NumberFormat(
                  Number(item?.gross),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          credit:
            item?.transaction === "Cr"
              ? NumberFormat(
                  Number(item?.gross),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          // balance: NumberFormat(
          //   Number(item?.balance),
          //   orgCurrencyCode,
          //   orgCurrencyList
          // ),
          gross: handleNegativeValueWithTransactionType(
            NumberFormat(Number(item?.gross), orgCurrencyCode, orgCurrencyList),
            item?.gross_type
          ),
        },
        action:
          item?.transaction_type === "Invoice"
            ? {
                url: `/invoice/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Invoices"),
                customNavigation: true,
              }
            : item?.transaction_type === "Credit Note"
            ? {
                url: `/credit-note/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Credit Notes"),
                customNavigation: true,
              }
            : item?.transaction_type === "Payments Received"
            ? {
                url: `/payment-received/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : item?.transaction_type === "Bill"
            ? {
                url: `/bill/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Bills"),
                customNavigation: true,
              }
            : item?.transaction_type === "Debit Note"
            ? {
                url: `/debit-note/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Debit Notes"),
                customNavigation: true,
              }
            : item?.transaction_type === "Payments Made"
            ? {
                url: `/payment-made/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : item?.transaction_type === "Journal Entry"
            ? {
                url: `/journal-entry/detail/${item?.transaction_id}${props.filterString}`,
                permission: subModulePermission.includes("Journal Entry"),
                customNavigation: true,
              }
            : item?.transaction_type === "Customer Payment Refund"
            ? {
                url: `/payment-received/detail/${item?.entity_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : item?.transaction_type === "Vendor Payment Refund"
            ? {
                url: `/payment-made/detail/${item?.entity_id}${props.filterString}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : {
                url: "",
                permission: subModulePermission.includes("Invoices"),
                customNavigation: false,
              },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
        className: navigation ? "row-navigation" : "",
      };
    } else {
      return {
        show: {
          date: item?.transaction_date,
          account: item?.account,
          credit:
            item?.transaction === "Cr"
              ? NumberFormat(
                  Number(item?.balance),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          balance: "",
        },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
      };
    }
  };
  const data = () => {
    let report: any = [];
    if (props.accountTransactionData.transactions) {
      props.accountTransactionData.transactions.map((item: any, index: any) => {
        if (item?.transactions_list !== undefined) {
          if (item?.group_name && item?.group_name !== "") {
            let lineItem = {
              group_name: item?.group_name,
            };
            let reponse = showGroup(lineItem);
            report.push(reponse);
          }
          if (item?.opening_balance !== undefined) {
            let lineItem = {
              transaction_date: `As on ${DateFormatHandler(
                props.accountTransactionData.start_date
              )}`,
              account: "Opening Balance",
              transaction: "Cr",
              balance: item?.opening_balance,
              colspan:
                Object.values(currentUserInfo.user_access_mapping)
                  .toString()
                  .split(",").length > 1
                  ? 6
                  : 5,
            };
            let reponse = showTransactions(lineItem, false, "");
            report.push(reponse);
          }
          if (item?.transactions_list) {
            item?.transactions_list?.map((trans: any, transIndex: any) => {
              let reponse = showTransactions(trans, true, "transaction");
              report.push(reponse);
            });
          }
          if (item?.closing_balance !== undefined) {
            let lineItem = {
              transaction_date: `As on ${DateFormatHandler(
                props.accountTransactionData.end_date
              )}`,
              account: "Closing Balance",
              transaction: "Cr",
              balance: item?.closing_balance,
              colspan:
                Object.values(currentUserInfo.user_access_mapping)
                  .toString()
                  .split(",").length > 1
                  ? 6
                  : 5,
            };
            let reponse = showTransactions(lineItem, false, "");
            report.push(reponse);
          }
        } else {
          let reponse = showTransactions(item, true, "transaction");
          report.push(reponse);
        }
      });
    }
    return report;
  };

  const navigation = (row: Row<TableData>) => {
    if (row.values.action.url) {
      navigate(`?${props.filterString}`);
      navigate(row.values.action.url);
    }
  };

  return (
    <div className="table-wrapper">
      <DetailsTableContainer
        columns={columns}
        data={data()}
        className={"table report-table soa-table acc-transaction"}
        hiddenColumns={hiddenColumns}
        haveColumnClassName={true}
        rowNavigation={navigation}
      >
        <EmptyReport heading="No transactions found!" alt="Empty Report" />
      </DetailsTableContainer>
    </div>
  );
};

export default DayBookTable;
