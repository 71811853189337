import "../styles/InfoTag.css";

type Props = {
  status: string;
  statusAddon?: string;
};
const InfoTag: React.FC<Props> = ({ status, statusAddon }) => {
  const statusColor = (status: string) => {
    let value = status?.toLowerCase();

    switch (value) {
      case "paid":
        return "info-tag tag-succuss";
      case "partially paid":
        return "info-tag tag-Partial";
      case "open":
        return "info-tag tag-warning";
      case "overdue":
        return "info-tag tag-danger";
      case "draft":
        return "info-tag tag-primary";
      case "future":
        return "info-tag tag-primary";
      case "in trial":
        return "info-tag tag-primary";
      case "approved":
        return "info-tag tag-primary";
      case "allocated":
        return "info-tag tag-succuss";
      case "published":
        return "info-tag tag-succuss";
      case "closed":
        return "info-tag tag-succuss";
      case "invoiced":
        return "info-tag tag-succuss";
      case "excess":
        return "info-tag tag-danger";
      case "non renewing":
        return "info-tag tag-warning";
      case "stopped":
        return "info-tag tag-warning";
      case "stop":
        return "info-tag tag-warning";
      case "declined":
        return "info-tag tag-warning";
      case "active":
        return "info-tag tag-succuss";
      case "expired":
        return "info-tag tag-danger";
      case "cancelled":
        return "info-tag tag-void";
      case "void":
        return "info-tag tag-void";
      case "exhausted":
        return "info-tag tag-danger";
      default:
        return "";
    }
  };
  return (
    <>
      <div
        className={statusColor(
          status?.includes("Overdue") ? "overdue" : status
        )}
      >
        {`${status}${statusAddon ? statusAddon : ""}`}
      </div>
    </>
  );
};
export default InfoTag;
