import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { CheckedBox } from "../../../../../types";
import {
  getReportingTagDropdown,
  reportingTagsListSelector,
} from "../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import {
  reportCustomizationFilterSelector,
  reportTableFilterSelector,
  selectedTagsDataSelector,
  setReportCustomizationFilters,
  setReportCustomizationFlag,
  setReportTableFilters,
  setSelectedTagsData,
} from "../../../../home/reports/ReportSlice";
import { currentUserSelector } from "../../../commonSlice";
import { customSelectStyle } from "../../SelectCustomStyle";
import { SaveButton } from "../../button/SaveButton";
import { SecondaryButton } from "../../button/SecondaryButton";
import { FormSelectField } from "../../formSelectField/FormSelectField";
import { ReportTagDropDown } from "../../reportingTagDropDown/reportingTagDropDown";
import { AdvanceFilterInfoTooltip } from "../AdvancedFilterInfoTooltip/AdvanceFilterInfoTooltip";
import { groupByOptions, reportBasis } from "../OptionConstants";
import { AddFilter } from "./addFilterComponent";
import PredefinedDateRangePicker from "../../../../home/reports/componets/PredefinedDateRange";
import "../CustomizeReport.css";

type Props = {
  handleClose: () => void;
  reportType: string;
  handleReport: (type: string, arg?: { [key: string]: any }) => void;
  onSubmit?: () => void;
};
export const CustomizeReportAtandDBConfigForm = (props: Props) => {
  const location = useLocation();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const tableFilters = useAppSelector(reportTableFilterSelector);
  const accountTransactionFilters = useAppSelector(
    reportCustomizationFilterSelector
  ).accountTransactionFilters;
  const [filters, setFilters] = useState<
    {
      field: string;
      comparator: string;
      value: string | string[];
    }[]
  >(accountTransactionFilters.advanced_filter);
  const [advancedFilterError, setAdvancedFilterError] = useState("");
  const [advancedFilterErrorIndices, setAdvancedFilterErrorIndices] = useState<
    number[]
  >([]);
  const addFilterRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [selectedTags, setSelectedTags] = useState<CheckedBox[]>([]);
  const reportingTagsDropdownList = useAppSelector(reportingTagsListSelector);
  const selectedTagsData = useAppSelector(selectedTagsDataSelector);
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filter = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;
  const options = {
    group_by: groupByOptions,
    report_basis: reportBasis,
  };

  // Function to update formik's initial values based on localStorage
  const getInitialValues = () => {
    const drillDownFilter = localStorage.getItem("drill-down-filter");
    if (drillDownFilter) {
      const parsedFilter = JSON.parse(drillDownFilter);
      return {
        start_date:
          parsedFilter.start_date ||
          tableFilters.start_date ||
          accountTransactionFilters.start_date,
        end_date:
          parsedFilter.end_date ||
          tableFilters.end_date ||
          accountTransactionFilters.end_date,
        date_range:
          parsedFilter.date_range ||
          tableFilters.date_range ||
          accountTransactionFilters.date_range,
        group_by: parsedFilter.group_by || accountTransactionFilters.group_by,
        report_basis:
          parsedFilter.report_basis || accountTransactionFilters.report_basis,
        advanced_filter:
          parsedFilter.advanced_filter ||
          accountTransactionFilters.advanced_filter,
        tag_option_in: parsedFilter.tag_option_in || [],
      };
    }
    return {
      start_date: tableFilters?.start_date
        ? tableFilters.start_date
        : accountTransactionFilters.start_date,
      end_date: tableFilters?.end_date
        ? tableFilters.end_date
        : accountTransactionFilters.end_date,
      date_range: tableFilters?.date_range
        ? tableFilters.date_range
        : accountTransactionFilters.date_range,
      group_by: accountTransactionFilters.group_by,
      report_basis: accountTransactionFilters.report_basis,
      advanced_filter: accountTransactionFilters.advanced_filter,
      tag_option_in: [],
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  useEffect(() => {
    setInitialValues(getInitialValues());
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.report_basis === "Cash") {
        values.tag_option_in = [];
      }
      dispatch(
        setReportTableFilters({
          ...tableFilters,
          start_date: tableFilters.start_date
            ? tableFilters.start_date
            : values.start_date,
          end_date: tableFilters.end_date
            ? tableFilters.end_date
            : values.end_date,
          date_range: tableFilters.date_range
            ? tableFilters.date_range
            : values.date_range,
          tag_option_in: values.tag_option_in,
        })
      );
      localStorage.setItem(
        "drill-down-filter",
        JSON.stringify({
          ...values,
          advanced_filter: filters,
        })
      );
      dispatch(
        setReportCustomizationFilters({
          accountTransactionFilters: { ...values, advanced_filter: filters },
        })
      );
      dispatch(setSelectedTagsData(selectedTags));
      dispatch(setReportCustomizationFlag(true));
      props.handleReport("submit", {
        ...values,
        start_date: tableFilters.start_date
          ? tableFilters.start_date
          : values.start_date,
        end_date: tableFilters.end_date
          ? tableFilters.end_date
          : values.end_date,
        date_range: tableFilters.date_range
          ? tableFilters.date_range
          : values.date_range,
        tag_option_in: values.tag_option_in,
        advanced_filter: filters,
      });
      props.handleClose();
    },
  });

  useEffect(() => {
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  }, [currentUserInfo.organization_id]);
  useEffect(() => {
    if (tableFilters.tag_option_in && tableFilters.tag_option_in?.length > 0) {
      let prevSelectedTags = JSON.parse(JSON.stringify(selectedTagsData));
      setSelectedTags(prevSelectedTags);
    } else {
      dispatch(setSelectedTagsData([]));
      setSelectedTags([]);
    }
  }, [tableFilters?.tag_option_in]);

  useEffect(() => {
    if (
      (formik.values.report_basis === "Accrual" && props.reportType === "AT") ||
      props.reportType === "DB"
    ) {
      let optionIds: any = [];
      selectedTags.map((item) => {
        optionIds = [...optionIds, ...item.optionIDs];
      });
      formik.setValues({
        ...formik.values,
        tag_option_in: optionIds,
      });
    } else {
      formik.setValues({
        ...formik.values,
        tag_option_in: [],
      });
    }
  }, [selectedTags, formik.values.report_basis]);

  /**
   * Function to set start date and end date
   */
  const handleDateRange = (start: any, end: any, range: string) => {
    let dateRange = range.replace(" ", "_").toLowerCase();
    formik.setFieldValue("start_date", start);
    formik.setFieldValue("end_date", end);
    formik.setFieldValue("date_range", dateRange);
  };
  const addFilter = () => {
    setAdvancedFilterError("");
    let updatedFilter = [...filters];
    updatedFilter.push({
      field: "",
      comparator: "",
      value: "",
    });
    setFilters([...updatedFilter]);
  };

  const deleteFilter = (index: number) => {
    setAdvancedFilterError("");
    setAdvancedFilterErrorIndices([]);
    let prevFilter = [...filters];
    prevFilter.splice(index, 1);
    setFilters([...prevFilter]);
  };
  const getAdvancedFilterValue = async (
    data: {
      field: string;
      comparator: string;
      value: string | string[];
    },
    index: number
  ) => {
    let prevFilters = [...filters];
    prevFilters[index] = await data;
    setFilters([...prevFilters]);
  };
  const validateAdvancedFilter = () => {
    let tempFilters = filters || [];

    return tempFilters
      .map((obj, index) => ({ obj, index })) // Create an array of objects with their corresponding indices
      .filter(({ obj }) => Object.values(obj).some((value) => value === ""))
      .map(({ index }) => index);
  };
  const handleSubmit = () => {
    props.onSubmit && props.onSubmit();
    addFilterRef.current?.handleSubmit();
    const isError = addFilterRef.current?.isError;
    setAdvancedFilterErrorIndices(validateAdvancedFilter());
    if (validateAdvancedFilter().length > 0) {
      setAdvancedFilterError(
        "Please fill the advanced filter completely or delete unused rows!"
      );
    } else if (!isError && validateAdvancedFilter().length === 0) {
      setAdvancedFilterError("");
      formik.submitForm();
    }
  };
  return (
    <form>
      <div className="customize-report-wrapper">
        <div>
          {
            <PredefinedDateRangePicker
              isSingleDatePicker={props.reportType === "DB" ? true : false}
              isAdvanced={true}
              defaultValue={props.reportType === "DB" ? "today" : "quarter"}
              reportType={props.reportType}
              resetType={""}
              handleDateRange={handleDateRange}
              filterdata={
                props.reportType === "DB"
                  ? {}
                  : {
                      startDate: formik.values.start_date,
                      endDate: formik.values.end_date,
                      dateRange: formik.values.date_range,
                    }
              }
            />
          }
        </div>
        <div className="row">
          <FormSelectField
            name="report_basis"
            id="report_basis"
            className="form-select customize-report-select"
            isDisabled={false}
            isOnlyInRow={false}
            defaultValue={{ label: "Accrual", value: "Accrual" }}
            styles={customSelectStyle}
            placeholder="Choose a report basis"
            isRequired={false}
            isSearchable={true}
            error=""
            label="Report Basis"
            onChange={(e: any) => formik.setFieldValue("report_basis", e.value)}
            options={options.report_basis}
            value={{
              label: formik.values.report_basis || "Accrual",
              value: formik.values.report_basis || "Accrual",
            }}
          />
          {!(props.reportType === "DB") && (
            <FormSelectField
              name="group_by"
              id="group_by"
              className="form-select customize-report-select"
              isDisabled={false}
              isOnlyInRow={false}
              styles={customSelectStyle}
              placeholder="Choose a group by option"
              isRequired={false}
              isSearchable={true}
              error=""
              label="Group By"
              onChange={(e: any) => {
                formik.setFieldValue("group_by", e.label);
                formik.setFieldValue("get_groups", !!e.label);
              }}
              options={options.group_by}
              value={{
                label: formik.values.group_by,
                value: formik.values.group_by,
              }}
            />
          )}
        </div>
        <div className="row">
          {formik.values.report_basis === "Accrual" &&
            currentUserInfo.is_tags_enabled &&
            (props.reportType === "AT" || props.reportType === "DB") && (
              <div
                className={`select-field-wrap ${
                  props.reportType === "AT" ? "col-6" : "col-12"
                }`}
              >
                <label className="fw-bold">Reporting Tags</label>
                <ReportTagDropDown
                  data={reportingTagsDropdownList}
                  checked={selectedTags}
                  setChecked={setSelectedTags}
                  editData={filter?.tag_option_in ? filter.tag_option_in : []}
                />
              </div>
            )}
        </div>
        <div>
          <div className="d-flex align-items-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                addFilter();
              }}
              className="no-appearance "
              disabled={filters && filters.length > 2}
            >
              <span className="w-100 d-flex  add-new m-0 ">Add Filter</span>
            </button>
            <AdvanceFilterInfoTooltip maxAdvancedFilterLimitInWords="three" />
          </div>
          {filters &&
            filters?.map((filter, index) => (
              <div key={index}>
                <AddFilter
                  key={index}
                  value={filter}
                  onDelete={() => deleteFilter(index)}
                  index={index}
                  getFilterValues={getAdvancedFilterValue}
                  ref={addFilterRef}
                  selectedFields={filters.map((filter) => {
                    return filter.field;
                  })}
                  isError={advancedFilterErrorIndices.includes(index)}
                />
              </div>
            ))}
          <span className="error">{advancedFilterError}</span>
        </div>
        <div className="customize_report_btn_group mt-3">
          <SaveButton
            type="button"
            buttonContent="Save"
            onClick={handleSubmit}
            isDisabled={false}
          />
          <SecondaryButton
            type={"button"}
            buttonContent="Cancel"
            onClick={props.handleClose}
            isDisabled={false}
          />
        </div>
      </div>
    </form>
  );
};
