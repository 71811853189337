import React, { useEffect, useState } from "react";
import { Cell, Row, useTable } from "react-table";
import { TableData } from "../../../../../../types";

import "../../../../../../index.css";
import "./AccountTransactionsCollapsibleTable.css";

import AccountTransactionsCollapsibleRow from "../accountTransactionsCollapsibleRow/AccountTransactionsCollapsibleRow";

type Props = {
  id?: string;
  columns: any[];
  // data: TableData[];
  data: any;
  tableClassName: string;
  dataSort: boolean;
  isLoading?: boolean;
  haveColumnClassName: boolean;
  rowClassName: string;
  emptyClassName: string;
  hiddenColumns?: string[];
  filterString?: string;
  onSortClick?: (e: string) => void;
  tbodyRowOnClick: (value: Row<TableData>, e: React.MouseEvent) => void;
  tbodyColumnClick: (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => void;
  children: React.ReactNode;
};

export default function AccountTransactionsCollapsibleTable(props: Props) {
  // const hashFragment = props.filterString;
  // // Remove the '#' character from the hash fragment
  // const decodedFilterString = hashFragment
  //   ? decodeURIComponent(hashFragment.slice(1))
  //   : undefined;
  // // // Parse the filter data as needed
  // const filters = decodedFilterString
  //   ? JSON.parse(decodedFilterString)
  //   : undefined;

  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //   useTable({
  //     columns: props.columns,
  //     data: props.data,
  //     initialState: {
  //       hiddenColumns: props.hiddenColumns ? props.hiddenColumns : [],
  //     },
  //   });

  const { groups, ...filterData } = props.data;

  return (
    <>
      {props.data?.groups.length > 0 ? (
        <table id={props.id} className={props.tableClassName}>
          {/* <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <th
                  {...column.getHeaderProps()}
                  data-orderable={props.dataSort ? column.render("sort") : ""}
                  onClick={() =>
                    props.dataSort && props.onSortClick
                      ? props.onSortClick(column.render("Header") as string)
                      : ""
                  }
                  className={
                    (props.dataSort
                      ? (column.render("sortOrder") as string)
                      : "") +
                    (props.haveColumnClassName
                      ? " " + (column.render("className") as string)
                      : "")
                  }
                >
                  <span>{column.render("Header")}</span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead> */}

          <tbody>
            {props.data?.groups.map((group: any, index: number) => (
              <AccountTransactionsCollapsibleRow
                key={group.filter}
                // filterData={filters}
                group={group}
                filterString={props.filterString}
                index={index}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="empty-report-table-container">{props.children}</div>
      )}
    </>
  );
}
