import React, { useEffect, useId, useMemo, useState } from "react";

import "./TransactionsTable.css";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../../common/commonSlice";
import { getAccountTransactionReportNew } from "../../../ReportSlice";
import TableSkeletonLoader from "../../../../../common/components/ui/Loaders/TableSkeletonLoader/TableSkeletonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DateFormatHandler,
  handleNegativeValueWithTransactionType,
} from "../../../../../../helpers/helper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import AccountNameDisplay from "../../../../../common/components/accountNameDispaly/AccountNameDisplay";
import { ObjectType, TableData } from "../../../../../../types";
import { useSubModulePermission } from "../../../../../hooks/useSubModulePermissions";
import Table from "../../../../../common/components/table/Table";
import { Row } from "react-table";
import EmptyReport from "../../../../../common/components/EmptyReport";
import { useNavigate } from "react-router-dom";

type Props = {
  filterString?: string;
  filter: any;
};
const TransactionsTable = ({ filter, filterString }: Props) => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [orgCurrencyCode, setOrgCurrencyCode] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(["action"]);

  const id = useId();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const subModulePermission = useSubModulePermission();
  const navigate = useNavigate();

  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();

  const hashFragment = filterString;
  // Remove the '#' character from the hash fragment
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  // // Parse the filter data as needed
  const filterData = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    setOrgCurrencyCode(
      orgCurrencyList.filter((item: ObjectType) => {
        return item?.id === filterData.currency_id;
      })[0]?.code
    );
  }, [filterData.currency_id]);

  useEffect(() => {
    if (
      Object.values(currentUserInfo.user_access_mapping).toString().split(",")
        .length <= 1
    )
      setHiddenColumns(["action", "Organization"]);
    else setHiddenColumns(["action"]);
  }, [currentUserInfo.user_access_mapping]);

  /*api call for getting transactions while expanding*/
  const getTransactions = async () => {
    let filterValues = {
      currency_id: filterData.currency_id,
      organization_id: filterData.organization_id,
      start_date: filterData.start_date,
      end_date: filterData.end_date,
      date_range: filterData.date_range,
      account_id: filterData.account_id,
      tag_option_in: filterData.tag_option_in ? filterData.tag_option_in : [],
      get_groups: false,
      group_filter: filter,
    };
    setIsLoading(true);
    const responseAction = await dispatch(
      getAccountTransactionReportNew({
        filterValues: filterValues,
        orgId: currentUserInfo.organization_id,
        pageNum: pageNo,
        pageSize: 25,
      })
    );
    const response = responseAction.payload;
    setTransactions((prevValue) => {
      return [...prevValue, ...response.transaction_details.transactions];
    });
    if (response.pagination?.next === null) {
      setHasMore(false);
    } else {
      setPageNo((value) => {
        return value + 1;
      });
    }
    setIsLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        className: "date text-align-left",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "table_account_name text-align-left",
      },
      {
        Header: "Organization",
        accessor:
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organizationName"
            : "Organization",
        className: "org text-align-left",
      },
      {
        Header: "Transaction Details",
        accessor: "show.transactionDetails",
        className: "trans-details text-align-left",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "trans-type text-align-left",
      },
      {
        Header: "Reference #",
        accessor: "show.reference",
        className: "ref text-align-left",
      },
      {
        Header: "Debit",
        accessor: "show.debit",
        className: "debit text-align-right",
      },
      {
        Header: "Credit",
        accessor: "show.credit",
        className: "credit text-align-right",
      },
      {
        Header: "Gross",
        accessor: "show.gross",
        className: "gross text-align-right",
      },
      {
        Header: "Running Balance",
        accessor: "show.runningBalance",
        className: "running_balance text-align-right",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    [transactions]
  );

  const showTransactions = (item: any, navigation: boolean, type: string) => {
    if (type === "transaction") {
      return {
        show: {
          date:
            type === "transaction"
              ? DateFormatHandler(item?.transaction_date)
              : item?.transaction_date,
          account: (
            <AccountNameDisplay
              accountName={item?.account}
              ledgerRoot={item?.account_path}
            />
          ),
          organizationName: item?.organization_name,
          transactionDetails: item?.transaction_detail,
          transactionType: item?.transaction_type,
          reference: item?.reference_number,
          debit:
            item?.transaction === "Dr"
              ? NumberFormat(
                  Number(item?.balance),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          credit:
            item?.transaction === "Cr"
              ? NumberFormat(
                  Number(item?.balance),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",

          gross: handleNegativeValueWithTransactionType(
            NumberFormat(Number(item?.gross), orgCurrencyCode, orgCurrencyList),
            item?.gross_type
          ),
          runningBalance: NumberFormat(
            Number(item?.running_balance),
            orgCurrencyCode,
            orgCurrencyList
          ),
        },
        action:
          item?.transaction_type === "Invoice"
            ? {
                url: `/invoice/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Invoices"),
                customNavigation: true,
              }
            : item?.transaction_type === "Credit Note"
            ? {
                url: `/credit-note/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Credit Notes"),
                customNavigation: true,
              }
            : item?.transaction_type === "Payments Received"
            ? {
                url: `/payment-received/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : item?.transaction_type === "Bill"
            ? {
                url: `/bill/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Bills"),
                customNavigation: true,
              }
            : item?.transaction_type === "Debit Note"
            ? {
                url: `/debit-note/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Debit Notes"),
                customNavigation: true,
              }
            : item?.transaction_type === "Payments Made"
            ? {
                url: `/payment-made/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : item?.transaction_type === "Journal Entry"
            ? {
                url: `/journal-entry/detail/${item?.transaction_id}${filterString}`,
                permission: subModulePermission.includes("Journal Entry"),
                customNavigation: true,
              }
            : item?.transaction_type === "Customer Payment Refund"
            ? {
                url: `/payment-received/detail/${item?.entity_id}${filterString}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : item?.transaction_type === "Vendor Payment Refund"
            ? {
                url: `/payment-made/detail/${item?.entity_id}${filterString}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : {
                url: "",
                permission: subModulePermission.includes("Invoices"),
                customNavigation: false,
              },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
        className: navigation ? "row-navigation" : "",
      };
    } else {
      return {
        show: {
          date: item?.transaction_date,
          account: item?.account,
          credit:
            item?.transaction === "Cr"
              ? NumberFormat(
                  Number(item?.balance),
                  orgCurrencyCode,
                  orgCurrencyList
                )
              : "",
          balance: "",
        },
        colSpan: { account: item?.colspan ? item?.colspan : 1 },
      };
    }
  };
  const data = () => {
    let report: any = [];
    if (transactions) {
      transactions.map((item: any, index: any) => {
        if (item?.transactions_list !== undefined) {
          if (item?.opening_balance !== undefined) {
            let lineItem = {
              transaction_date: `As on ${DateFormatHandler(
                filterData.start_date
              )}`,
              account: "Opening Balance",
              transaction: "Cr",
              balance: item?.opening_balance,
              colspan:
                Object.values(currentUserInfo.user_access_mapping)
                  .toString()
                  .split(",").length > 1
                  ? 6
                  : 5,
            };
            let reponse = showTransactions(lineItem, false, "");
            report.push(reponse);
          }
          if (item?.transactions_list) {
            item?.transactions_list?.map((trans: any, transIndex: any) => {
              let reponse = showTransactions(trans, true, "transaction");
              report.push(reponse);
            });
          }
          if (item?.closing_balance !== undefined) {
            let lineItem = {
              transaction_date: `As on ${DateFormatHandler(
                filterData.end_date
              )}`,
              account: "Closing Balance",
              transaction: "Cr",
              balance: item?.closing_balance,
              colspan:
                Object.values(currentUserInfo.user_access_mapping)
                  .toString()
                  .split(",").length > 1
                  ? 6
                  : 5,
            };
            let reponse = showTransactions(lineItem, false, "");
            report.push(reponse);
          }
        } else {
          let reponse = showTransactions(item, true, "transaction");
          report.push(reponse);
        }
      });
    }
    return report;
  };
  const navigation = (row: Row<TableData>) => {
    if (row.values.action.url) {
      navigate(`?${filterString}`);
      navigate(row.values.action.url);
    }
  };
  const tbodyRowOnClick = (row: Row<TableData>) => {
    if (row.values.action.customNavigation) navigation(row);
  };
  return (
    <>
      <div className="collapsible-table-wrapper w-100" id={id}>
        <InfiniteScroll
          dataLength={transactions?.length}
          hasMore={hasMore}
          next={() => {
            getTransactions();
          }}
          endMessage={
            <span className="w-100 d-flex justify-content-center"></span>
          }
          height={500}
          loader={<span></span>}
          scrollableTarget={id}
        >
          <Table
            id={""}
            columns={columns}
            data={data()}
            tableClassName={
              "accordion-content-body accordion-collapsible-body-table"
            }
            dataSort={false}
            isLoading={false}
            haveColumnClassName={true}
            rowClassName={"table-navigation"}
            emptyClassName={"text-center dataTables_empty"}
            hiddenColumns={hiddenColumns}
            tbodyRowOnClick={tbodyRowOnClick}
            tbodyColumnClick={() => {}}
          >
            {isLoading ? (
              <TableSkeletonLoader colCount={10} rowCount={25} />
            ) : (
              <EmptyReport
                heading="No transactions found!"
                alt="Empty Report"
              />
            )}
          </Table>
          {isLoading && transactions.length > 0 ? (
            <TableSkeletonLoader colCount={10} rowCount={25} />
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default TransactionsTable;
